/** @jsx jsx */

import React, { Suspense, lazy } from 'react'
import { jsx } from '@emotion/react'
import styled from '@emotion/styled'
import { Header } from './header'
import { GLCanvas } from '../gl/gl-canvas'
import { GlobalStyles } from './global-styles'

type LayoutProps = {
  children?: React.ReactNode
  location: Location
  headerTitle: string
  pageTitle?: string
}

// @ts-expect-error
const ROOT_PATH = `${__PATH_PREFIX__}/`
const RESUME_PATH = `/resume/`
const LINKS_PATH = '/links/'
const BREAKPOINTS = [576, 768, 960, 1280]
export const mq = BREAKPOINTS.map((bp) => `@media (max-width: ${bp}px)`)

export default function Layout({ children, location }: LayoutProps) {
  const isHome = location.pathname === ROOT_PATH
  const isResume = location.pathname == RESUME_PATH
  const isLinks = location.pathname == LINKS_PATH

  return (
    <MainLayoutContainer className="main-layout-container">
      <GlobalStyles />

      <Suspense fallback={null}>
        {isHome && <GLCanvas onLoad={null} />}
      </Suspense>

      <Header
        isHome={location.pathname == '/'}
        isResume={isResume}
        displayTitle={!isLinks}
      />
      <div id="mobile-nav-portal"></div>
      {children}
    </MainLayoutContainer>
  )
}

const MainLayoutContainer = styled.main`
  margin-left: auto;
  margin-right: auto;
  max-width: 960px;
  padding: 48px;
  ${mq[1]} {
    padding: 24px;
  }

  @media print {
    padding: 12px;
  }
`

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-feed-tsx": () => import("./../../../src/pages/feed.tsx" /* webpackChunkName: "component---src-pages-feed-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-journal-tsx": () => import("./../../../src/pages/journal.tsx" /* webpackChunkName: "component---src-pages-journal-tsx" */),
  "component---src-pages-links-tsx": () => import("./../../../src/pages/links.tsx" /* webpackChunkName: "component---src-pages-links-tsx" */),
  "component---src-pages-music-tsx": () => import("./../../../src/pages/music.tsx" /* webpackChunkName: "component---src-pages-music-tsx" */),
  "component---src-pages-resume-tsx": () => import("./../../../src/pages/resume.tsx" /* webpackChunkName: "component---src-pages-resume-tsx" */),
  "component---src-pages-software-tsx": () => import("./../../../src/pages/software.tsx" /* webpackChunkName: "component---src-pages-software-tsx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-journal-2017-index-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/journal/2017/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-journal-2017-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-journal-2019-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/journal/2019/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-journal-2019-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-journal-2023-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/journal/2023/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-journal-2023-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-journal-5-things-music-taught-me-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/journal/5-things-music-taught-me/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-journal-5-things-music-taught-me-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-journal-about-this-website-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/journal/about-this-website/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-journal-about-this-website-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-journal-for-nihon-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/journal/for-nihon/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-journal-for-nihon-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-journal-just-married-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/journal/just-married/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-journal-just-married-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-journal-looking-for-alaska-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/journal/looking-for-alaska/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-journal-looking-for-alaska-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-journal-origins-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/journal/origins/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-journal-origins-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-journal-reflecting-on-3-years-at-viget-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/journal/reflecting-on-3-years-at-viget/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-journal-reflecting-on-3-years-at-viget-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-journal-undiscovered-colors-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/journal/undiscovered-colors/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-journal-undiscovered-colors-index-mdx" */)
}

